import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../store';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import {
  Checkbox,
  debounce,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { DataGridPro, GridRenderCellParams, GridValueGetter } from '@mui/x-data-grid-pro';
import {
  clearFilter,
  SalesOrderItemFilter,
  setFilterValue,
  setPage,
  setPageSize,
  setSort
} from './orderItemSlice';
import {
  useCreateOrderItemMutation,
  useGetSalesOrderItemsAutocompleteQuery,
  useGetSalesOrderItemsQuery,
  useRecalculateSalesOrderItemMutation
} from '../api/orderApi';
import { Filter } from '../../components/Filter';
import { ItemAttributesFilter } from '../../components/ItemAttributesFilter';
import { FilterArea } from '../../components/FilterArea';
import { UnitType } from '../../domain/UnitType';
import { ExpandedOrderItem, Order } from './orderSlice';
import { formatNumber } from '../../utils/textFormatUtils';
import { useForm } from 'react-hook-form';
import { Loader } from '../../components/Loader';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { OrderItemsTable } from './OrderItemsTable';
import { roundToInteger, roundToTwoDecimals } from '../../utils/mathUtils';

export interface OrderItemsProps {
  order: Order;
  orderItems?: ExpandedOrderItem[];
}

export const OrderItems = ({ order, orderItems }: OrderItemsProps): JSX.Element => {
  const { orderId } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state: RootState) => state.auth);
  const { filter, page, pageSize, sort } = useSelector((state: RootState) => state.orderItems);
  const [searchFieldValue, setSearchFieldValue] = useState(filter.searchField || '');
  const {
    data: salesOrderItems,
    isLoading,
    isFetching
  } = useGetSalesOrderItemsQuery({
    filter,
    page,
    pageSize,
    sort
  });
  const [recalculateSalesOrderItem] = useRecalculateSalesOrderItemMutation();
  const [createOrderItem, { isLoading: isCreateOrderItemLoading }] = useCreateOrderItemMutation();
  const { data: customerItemTypeOptions, isFetching: isCustomerItemTypeOptionsLoading } =
    useGetSalesOrderItemsAutocompleteQuery('customerItemType');
  const {
    watch,
    register,
    setValue,
    formState: { errors }
  } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });
  const watchAllFields = watch();
  const { language } = useSelector((state: RootState) => state.language);

  useEffect(() => {
    dispatch(clearFilter());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      setSort([{ field: !!user?.customNumbers ? 'customerItemNumber' : 'itemName', sort: 'asc' }])
    );
  }, [dispatch, user]);

  const onChangeUnit = (itemId: number, unit: UnitType) => {
    recalculateSalesOrderItem({
      itemId,
      unit,
      filter: {
        filter,
        page,
        pageSize,
        sort
      }
    }).catch(() => {});
  };

  const addOrderItem = (params: GridRenderCellParams<any, void>) => {
    const quantity = params.api.getCellValue(params.id, 'orderQuantity');

    if (!quantity) {
      enqueueSnackbar(t('order.items.quantityMissing'), { variant: 'error' });
      return;
    }

    createOrderItem({
      orderId,
      request: { customerItemId: params.row.id, unit: params.row.unit, quantity }
    })
      .unwrap()
      .then(() => {
        enqueueSnackbar(t('order.items.itemAdded'), { variant: 'success' });
        setValue(`${params.row.id}.orderAllAvailable`, false);
        setValue(`${params.row.id}.orderPacks`, '');
      })
      .catch(() => {});
  };

  const handleSelectChange = (
    field: keyof SalesOrderItemFilter,
    event: any,
    value: string[] | boolean
  ) => {
    dispatch(setFilterValue({ field, value }));
  };

  const handleSearchFieldInputChange = (field: keyof SalesOrderItemFilter, value: string) => {
    setSearchFieldValue(value);
  };

  const debouncedSearchChangeHandler = useMemo(
    () =>
      debounce(
        (field: keyof SalesOrderItemFilter, value: any) =>
          dispatch(setFilterValue({ field, value })),
        500
      ),
    [dispatch]
  );

  const handleOnlyAvailableItemsChange = (
    field: keyof SalesOrderItemFilter,
    event: any,
    checked: boolean
  ) => {
    dispatch(setFilterValue({ field, value: checked }));
  };

  const getOrderQuantity: GridValueGetter = (value, row, column, apiRef): number | undefined => {
    // TODO row.id?
    debugger;
    const fields = watchAllFields[row.id];
    if (fields?.orderAllAvailable) {
      return row.availableQuantity;
    } else if (
      !fields?.orderPacks ||
      !Number.isInteger(Number(fields?.orderPacks)) ||
      (errors[row.id] as any)?.orderPacks
    ) {
      return undefined;
    }
    return fields.orderPacks * row.packQuantity;
  };

  const columns: GridColDef[] = [
    {
      field: 'customerItemNumber',
      flex: 1,
      headerName: t('order.items.customerItemNumber')
    },
    {
      field: 'customerItemName',
      flex: 2,
      headerName: t('order.items.customerItemName')
    },
    {
      field: 'itemName',
      flex: 2.5,
      headerName: t('order.items.description'),
      renderCell: (params) => (
        <span className="margin-top-4">
          {params.row.itemTranslations[language] || params.row.itemName}
          <FormHelperText sx={{ marginTop: 0, fontSize: '10px' }}>
            {t('order.items.itemNumberValue', { itemNumber: params.row.itemNumber })}
          </FormHelperText>
        </span>
      )
    },
    {
      field: 'availableQuantity',
      flex: 2,
      headerAlign: 'center',
      headerName: t('order.items.availableAmount'),
      align: 'center',
      renderCell: (params) => (
        <Grid container alignItems="center">
          <Grid item xs={4} textAlign="right">
            {formatNumber(roundToTwoDecimals(params.value))}
          </Grid>
          <Grid item xs={8}>
            <FormControl sx={{ marginLeft: '4px' }} size="small">
              <Select
                value={params.row.unit}
                sx={{ width: '110px', height: '28px', fontSize: '14px' }}
                onChange={(event) => onChangeUnit(params.row.id, event.target.value as UnitType)}
              >
                {Object.values(UnitType).map((unit) => (
                  <MenuItem key={unit} value={unit}>
                    {t(`unitType.${unit}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )
    },
    {
      field: 'availablePacks',
      flex: 1,
      headerName: t('order.items.availablePacks'),
      renderCell: (params) => (
        <span className="margin-top-4">
          {roundToInteger(params.row.availablePacks)}
          <FormHelperText sx={{ marginTop: 0, fontSize: '10px' }}>
            {t('order.items.availablePacksHelperText', {
              quantity: roundToTwoDecimals(params.row.packQuantity),
              unit: t(`unitType.${params.row.unit}`)
            })}
          </FormHelperText>
        </span>
      )
    },
    {
      field: 'orderPacks',
      flex: 1,
      headerName: t('order.items.orderPacks'),
      renderCell: (params) => (
        <TextField
          className="table-input"
          type="number"
          InputProps={{
            inputProps: { min: 0 }
          }}
          error={!!(errors[params.row.id] as any)?.orderPacks}
          helperText={(errors[params.row.id] as any)?.orderPacks?.message || ''}
          {...register(`${params.row.id}.orderPacks`, {
            pattern: {
              value: /^([1-9]\d*)$/,
              message: t('order.items.packsError')
            },
            onChange: () => setValue(`${params.row.id}.orderAllAvailable`, false)
          })}
        />
      )
    },
    {
      field: 'orderAllAvailable',
      flex: 1,
      headerName: t('order.items.orderAllAvailable'),
      renderCell: (params) => (
        <Checkbox
          {...(register(`${params.row.id}.orderAllAvailable`),
          {
            onChange: (event, checked) => {
              setValue(`${params.row.id}.orderAllAvailable`, checked);
              if (checked) {
                setValue(`${params.row.id}.orderPacks`, roundToInteger(params.row.availablePacks));
              }
            }
          })}
          checked={watchAllFields[params.row.id]?.orderAllAvailable || false}
        />
      )
    },
    {
      field: 'orderQuantity',
      flex: 1,
      headerName: t('order.items.orderQuantity'),
      valueGetter: getOrderQuantity,
      renderCell: (params) =>
        params.value
          ? t('order.items.quantityValue', {
              quantity: formatNumber(roundToTwoDecimals(params.value)),
              unit: t(`unitType.${params.row.unit}`)
            })
          : ''
    },
    {
      field: 'add',
      width: 50,
      headerName: '',
      sortable: false,
      align: 'right',
      renderCell: (params: GridRenderCellParams<any, void>) => (
        <IconButton onClick={() => addOrderItem(params)}>
          <Icon>shopping_cart</Icon>
        </IconButton>
      )
    }
  ];

  return (
    <Grid container>
      {isCreateOrderItemLoading && <Loader />}
      <FilterArea labelKey="order.items.searchItemsTitle" onClear={() => dispatch(clearFilter())}>
        <Filter
          id="searchField"
          label={t('order.items.searchField')}
          onChange={(event) => {
            handleSearchFieldInputChange('searchField', event.target.value);
            debouncedSearchChangeHandler('searchField', event.target.value);
          }}
          text
          value={searchFieldValue}
          width="290px"
        />
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                id="onlyAvailableItems"
                checked={filter.onlyAvailableItems || false}
                onChange={handleOnlyAvailableItemsChange.bind(this, 'onlyAvailableItems')}
              />
            }
            label={t('order.items.onlyAvailableItems')}
          />
        </Grid>

        <Filter
          id="customerItemType"
          extra
          label={t('items.table.customerItemType')}
          loading={isCustomerItemTypeOptionsLoading}
          minWidth="220px"
          multiple
          onChange={handleSelectChange.bind(this, 'customerItemType')}
          options={customerItemTypeOptions}
          value={filter.customerItemType || []}
        />

        <ItemAttributesFilter
          disabled={false}
          extra
          filter={filter}
          handleSelectChange={handleSelectChange}
        />
      </FilterArea>
      <Grid container className="margin-top-16 width-100">
        <Grid item xs sx={{ height: '646px' }}>
          <DataGridPro
            loading={isLoading || isFetching}
            columns={columns}
            disableColumnMenu
            disableRowSelectionOnClick
            disableVirtualization={process.env.NODE_ENV === 'test'}
            filterMode="server"
            columnHeaderHeight={46}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  customerItemNumber: !!user?.customNumbers,
                  customerItemName: !!user?.customNames
                }
              }
            }}
            paginationModel={{ page, pageSize }}
            onPaginationModelChange={({ pageSize, page }) => {
              dispatch(setPageSize(pageSize));
              dispatch(setPage(page));
            }}
            onSortModelChange={(sort) => dispatch(setSort(sort))}
            pagination
            paginationMode="server"
            rowHeight={55}
            rows={(salesOrderItems?.content || []) as GridRowsProp}
            rowCount={salesOrderItems?.totalElements || 0}
            pageSizeOptions={[10, 25, 50, 100]}
            sortingMode="server"
            sortModel={sort}
          />
        </Grid>
      </Grid>
      <OrderItemsTable order={order} orderItems={orderItems} />
    </Grid>
  );
};
