import {
  GroupedVendor,
  MoistureAnalyticsQuery,
  MoistureAnalyticsResult,
  ProducedPackageTracing,
  ProductionFilterValues,
  ProductionOrder,
  ProductionOrderAnalyticsRow,
  RawMaterialAnalyticsComparison,
  RawMaterialGroupedRow,
  UpdateProductionOrderAnalyticsStatusRequest,
  VendorsComparisonRequest,
  WorkStation
} from '../analytics';
import { apiSlice } from './apiSlice';
import { Page } from '../../domain/Page';
import { ProductionAnalyticsSearchParams } from '../analytics/production/productionAnalyticsSlice';
import { RawMaterialAnalyticsState } from '../analytics/rawMaterial/rawMaterialAnalyticsSlice';
import { ProductionOrderDetailsState } from '../analytics/production/details/productionOrderDetailsSlice';
import { ResponseHandler } from '@reduxjs/toolkit/src/query/fetchBaseQuery';
import { saveAs } from 'file-saver';

const analyticsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVendors: builder.query<GroupedVendor[], void>({
      query: () => ({
        url: '/production/vendors'
      }),
      providesTags: ['Vendor']
    }),
    getWorkStations: builder.query<WorkStation[], void>({
      query: () => ({
        url: '/production/work-stations'
      }),
      providesTags: ['WorkStation']
    }),
    getFilterValuesForConsumedPackages: builder.query<ProductionFilterValues, void>({
      query: () => ({
        url: '/production/consumed-packages-filter-values',
        method: 'GET'
      })
    }),
    getFilterValuesForProducedPackages: builder.query<ProductionFilterValues, void>({
      query: () => ({
        url: '/production/produced-packages-filter-values',
        method: 'GET'
      })
    }),
    getMoistureAnalytics: builder.query<MoistureAnalyticsResult[], MoistureAnalyticsQuery>({
      query: (body) => ({
        url: '/production/moisture-analytics',
        method: 'POST',
        body: body
      })
    }),
    downloadMoistureAnalytics: builder.query<void, MoistureAnalyticsQuery>({
      query: (body) => ({
        url: '/production/moisture-analytics/export',
        method: 'POST',
        body: body,
        responseHandler: responseHandlerFileDownload
      })
    }),
    getProductionOrderAnalytics: builder.query<
      Page<ProductionOrderAnalyticsRow>,
      ProductionAnalyticsSearchParams
    >({
      query: ({ page, pageSize, filter }) => ({
        url: '/production/production-analytics',
        params: {
          page,
          size: pageSize
        },
        method: 'POST',
        body: filter
      })
    }),
    downloadProductionAnalytics: builder.query<void, ProductionAnalyticsSearchParams>({
      query: (params) => ({
        url: '/production/production-analytics/export',
        method: 'POST',
        params: {
          page: params.page,
          size: params.pageSize
        },
        body: params.filter,
        responseHandler: responseHandlerFileDownload
      })
    }),
    getRawMaterialAnalytics: builder.query<Page<RawMaterialGroupedRow>, RawMaterialAnalyticsState>({
      query: (params) => ({
        url: '/production/raw-material-analytics',
        params: {
          page: params.page,
          size: params.pageSize
        },
        method: 'POST',
        body: params.filter
      })
    }),
    downloadRawMaterialAnalytics: builder.query<void, RawMaterialAnalyticsState>({
      query: (params) => ({
        url: '/production/raw-material-analytics/export',
        params: {
          page: params.page,
          size: params.pageSize
        },
        method: 'POST',
        body: params.filter,
        responseHandler: responseHandlerFileDownload
      })
    }),
    getRawMaterialAnalyticsComparison: builder.query<
      RawMaterialAnalyticsComparison[],
      VendorsComparisonRequest
    >({
      query: (params) => ({
        url: '/production/compare-vendors',
        method: 'POST',
        body: params
      })
    }),
    getProductionOrders: builder.query<Page<ProductionOrder>, ProductionOrderDetailsState>({
      query: (params) => ({
        url: '/production/production-orders',
        params: {
          page: params.page,
          size: params.pageSize
        },
        method: 'POST',
        body: params.filter
      }),
      providesTags: ['ProductionOrder']
    }),
    updateProductionOrderAnalyticsStatus: builder.mutation<
      void,
      {
        productionOrderId: number;
        body: UpdateProductionOrderAnalyticsStatusRequest;
      }
    >({
      query: ({ productionOrderId, body }) => ({
        url: `/production/production-order/${productionOrderId}/analytics-status`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['ProductionOrder']
    }),
    getPackageInfo: builder.query<ProducedPackageTracing, string>({
      query: (packageNumber) => ({
        url: '/production/package-tracking',
        params: { packageNumber },
        method: 'GET'
      })
    })
  })
});

const responseHandlerFileDownload: ResponseHandler = async (response: Response) => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const contentDispositionHeader = response.headers.get('content-disposition');
  const fileName =
    (contentDispositionHeader ? filenameRegex.exec(contentDispositionHeader)?.[1] : '') ||
    'export.xlsx';

  const blob = await response.blob();
  saveAs(blob, fileName);
};

export const {
  useGetVendorsQuery,
  useGetWorkStationsQuery,
  useLazyGetPackageInfoQuery,
  useGetProductionOrdersQuery,
  useGetMoistureAnalyticsQuery,
  useGetRawMaterialAnalyticsQuery,
  useGetProductionOrderAnalyticsQuery,
  useLazyDownloadMoistureAnalyticsQuery,
  useLazyDownloadProductionAnalyticsQuery,
  useLazyDownloadRawMaterialAnalyticsQuery,
  useGetRawMaterialAnalyticsComparisonQuery,
  useGetFilterValuesForConsumedPackagesQuery,
  useGetFilterValuesForProducedPackagesQuery,
  useUpdateProductionOrderAnalyticsStatusMutation
} = analyticsApi;
