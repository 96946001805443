import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { MaterialReactTable, MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import {
  combineFrequencyMaps,
  materialReactTableBaseProps,
  standardDeviationFromFrequencyMap,
  sum
} from '../analyticsHelper';
import { MoistureAnalysisGroupedRow, MoistureAnalyticsResult } from '../index';
import { getExpandedRowModel } from '../reactMaterialTableHack';
import { mapMoistureAnalyticsTableData } from './MoistureAnalyticsHelper';
import { formatNumber, formatNumberScale } from '../../../utils/textFormatUtils';
import { useTranslation } from 'react-i18next';

const getFilteredRows = (
  table: MRT_TableInstance<MoistureAnalysisGroupedRow>
): MoistureAnalysisGroupedRow[] => table.getFilteredRowModel().rows.map((row) => row.original);

export const MoistureAnalyticsTable = ({
  data,
  isLoading,
  isPackageSearch
}: {
  data: MoistureAnalyticsResult[];
  isLoading: boolean;
  isPackageSearch: boolean;
}) => {
  const { t } = useTranslation();
  const groupedData = useMemo(
    (): MoistureAnalysisGroupedRow[] => mapMoistureAnalyticsTableData(data),
    [data]
  );
  const columns = useMemo(
    () =>
      [
        {
          id: 'vendor',
          header: t('analytics.vendor'),
          Footer: t('analytics.total'),
          Cell: ({
            row: {
              original: { type, vendor, vendorGroup }
            }
          }) => {
            let value: string | undefined;
            const marginLeft = `${type === 'vendor' && vendorGroup ? 20 : 0}px`;
            switch (type) {
              case 'vendor':
                value = vendor;
                break;
              case 'vendor_group':
                value = vendorGroup;
                break;
              default:
                value = '';
            }
            return <span style={{ marginLeft }}>{value || '-'}</span>;
          }
        },
        {
          id: 'packageNo',
          accessorFn: (row) => (row.type === 'vendor' ? row.packageNo : ''),
          header: t('analytics.moistureAnalytics.packNum')
        },
        {
          id: 'avgMoisture',
          accessorFn: (row) => formatNumberScale(row.avgMoisture),
          header: `${t('analytics.avgMoisture')} %`,
          Footer: ({ table }) => {
            const filteredRows = getFilteredRows(table);
            const moistureAgg =
              filteredRows?.map((r) => r.avgMoisture * r.totalPcs).reduce(sum, 0) || 0;
            const totalPcs = filteredRows?.map((r) => r.totalPcs).reduce(sum, 0) || 0;
            return `${totalPcs > 0 ? formatNumberScale(moistureAgg / totalPcs) : 0} %`;
          }
        },
        {
          id: 'maxMoisture',
          accessorFn: (row) => formatNumberScale(row.maxMoisture),
          header: `${t('analytics.maxMoisture')} %`,
          Footer: ({ table }) => {
            const filteredRows = getFilteredRows(table);
            const maxMoisture =
              filteredRows
                ?.map((r) => r.maxMoisture)
                .sort()
                .reverse()?.[0] || 0;
            return formatNumberScale(maxMoisture);
          }
        },
        {
          id: 'moistureStandardDeviation',
          accessorFn: (row) => formatNumberScale(row.moistureStandardDeviation, 1),
          header: t('analytics.standardDeviation'),
          Footer: ({ table }) => {
            const filteredRows = getFilteredRows(table);
            const moistureFrequencyMap = combineFrequencyMaps(
              filteredRows.map((c) => c.moistureMap)
            );
            return formatNumberScale(standardDeviationFromFrequencyMap(moistureFrequencyMap), 1);
          }
        },
        {
          id: 'totalPcs',
          accessorFn: (row) => formatNumber(row.totalPcs),
          header: t('analytics.pcs'),
          Footer: ({ table }) => {
            const filteredRows = getFilteredRows(table);
            const totalPcs = filteredRows?.map((r) => r.totalPcs).reduce(sum, 0) || 0;
            return formatNumberScale(totalPcs);
          }
        },
        {
          id: 'moistureRejectPercentage',
          accessorFn: (row) => formatNumberScale(row.moistureRejectPercentage, 1),
          header: `${t('analytics.moistureAnalytics.moistureRejectPercentage')} %`,
          Footer: ({ table }) => {
            const filteredRows = getFilteredRows(table);
            const moistureRejectPcs =
              filteredRows?.map((r) => r.moistureRejectPieces).reduce(sum, 0) || 0;
            const totalPcs = filteredRows?.map((r) => r.totalPcs).reduce(sum, 0) || 0;
            return totalPcs > 0 ? formatNumberScale(moistureRejectPcs / totalPcs, 1) : 0;
          }
        }
      ] as MRT_ColumnDef<MoistureAnalysisGroupedRow>[],
    [t]
  );

  return (
    <Grid container className="margin-top-40">
      <Grid item xs sx={{ height: '40vh' }}>
        <MaterialReactTable<MoistureAnalysisGroupedRow>
          {...materialReactTableBaseProps}
          data={groupedData || []}
          columns={columns}
          enableStickyFooter
          enablePagination={false}
          enableRowVirtualization
          enableBottomToolbar={false}
          rowCount={groupedData?.length || 0}
          getExpandedRowModel={getExpandedRowModel}
          getRowCanExpand={(row) => !!row?.subRows?.length}
          getSubRows={(row) => row?.children || undefined}
          muiTableContainerProps={{ sx: { maxHeight: '60vh' } }}
          state={{ isLoading: isLoading, columnVisibility: { packageNo: isPackageSearch } }}
        />
      </Grid>
    </Grid>
  );
};
