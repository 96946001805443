import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../store';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import {
  Checkbox,
  debounce,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { DataGridPro, GridRenderCellParams, GridValueGetter } from '@mui/x-data-grid-pro';
import {
  useGetSalesOrderItemsAutocompleteQuery,
  useGetSalesOrderItemsQuery,
  useRecalculateSalesOrderItemMutation
} from '../api/orderApi';
import { Filter } from '../../components/Filter';
import { ItemAttributesFilter } from '../../components/ItemAttributesFilter';
import { FilterArea } from '../../components/FilterArea';
import { UnitType } from '../../domain/UnitType';
import { formatNumber } from '../../utils/textFormatUtils';
import { useForm } from 'react-hook-form';
import {
  clearFilter,
  setFilterValue,
  setPage,
  setPageSize,
  setSort,
  StockFilter
} from './stockSlice';
import { OrderSelection } from './OrderSelection';
import { OrderItemCreateRequest } from '../order/orderItemSlice';
import { useSnackbar } from 'notistack';
import { usePermission } from '../../hooks/usePermission';
import { Permission } from '../../domain/Permission';
import { roundToInteger, roundToTwoDecimals } from '../../utils/mathUtils';

export const Stock = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state: RootState) => state.auth);
  const { filter, page, pageSize, sort } = useSelector((state: RootState) => state.stock);
  const [searchFieldValue, setSearchFieldValue] = useState(filter.searchField || '');
  const {
    data: salesOrderItems,
    isLoading,
    isFetching
  } = useGetSalesOrderItemsQuery({
    filter,
    page,
    pageSize,
    sort
  });
  const [recalculateSalesOrderItem] = useRecalculateSalesOrderItemMutation();
  const { data: customerItemTypeOptions, isFetching: isCustomerItemTypeOptionsLoading } =
    useGetSalesOrderItemsAutocompleteQuery('customerItemType');
  const [itemRequest, setItemRequest] = useState<OrderItemCreateRequest>();
  const [orderSelectionOpen, setOrderSelectionOpen] = useState(false);
  const {
    watch,
    register,
    setValue,
    formState: { errors }
  } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });
  const watchAllFields = watch();
  const { language } = useSelector((state: RootState) => state.language);
  const { hasPermission: canEdit } = usePermission(Permission.STOCK_MANAGEMENT);

  useEffect(() => {
    dispatch(clearFilter());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      setSort([{ field: !!user?.customNumbers ? 'customerItemNumber' : 'itemName', sort: 'asc' }])
    );
  }, [dispatch, user]);

  const onChangeUnit = (itemId: number, unit: UnitType) => {
    recalculateSalesOrderItem({
      itemId,
      unit,
      filter: {
        filter,
        page,
        pageSize,
        sort
      }
    }).catch(() => {});
  };

  const openOrderSelection = (params: GridRenderCellParams<any, void>) => {
    const quantity = params.api.getCellValue(params.id, 'orderQuantity');

    if (!quantity) {
      enqueueSnackbar(t('stock.quantityMissing'), { variant: 'error' });
      return;
    }

    setItemRequest({ customerItemId: params.row.id, unit: params.row.unit, quantity });
    setOrderSelectionOpen(true);
  };

  const closeOrderSelection = () => {
    setOrderSelectionOpen(false);
    setItemRequest(undefined);
  };

  const handleSelectChange = (field: keyof StockFilter, event: any, value: string[] | boolean) => {
    dispatch(setFilterValue({ field, value }));
  };

  const handleSearchFieldInputChange = (field: keyof StockFilter, value: string) => {
    setSearchFieldValue(value);
  };

  const debouncedSearchChangeHandler = useMemo(
    () =>
      debounce(
        (field: keyof StockFilter, value: any) => dispatch(setFilterValue({ field, value })),
        500
      ),
    [dispatch]
  );

  const handleOnlyAvailableItemsChange = (
    field: keyof StockFilter,
    event: any,
    checked: boolean
  ) => {
    dispatch(setFilterValue({ field, value: checked }));
  };

  const getOrderQuantity: GridValueGetter = (value, row, column, apiRef): number | undefined => {
    // TODO row.id?
    debugger;
    const fields = watchAllFields[row.id];
    if (fields?.orderAllAvailable) {
      return row.availableQuantity;
    } else if (
      !fields?.orderPacks ||
      !Number.isInteger(Number(fields?.orderPacks)) ||
      (errors[row.id] as any)?.orderPacks
    ) {
      return undefined;
    }
    return fields.orderPacks * row.packQuantity;
  };

  const columns: GridColDef[] = [
    {
      field: 'customerItemNumber',
      flex: 1,
      headerName: t('stock.customerItemNumber')
    },
    {
      field: 'customerItemName',
      flex: 2,
      headerName: t('stock.customerItemName')
    },
    {
      field: 'itemName',
      flex: 2.5,
      headerName: t('stock.description'),
      renderCell: (params) => (
        <span className="margin-top-4">
          {params.row.itemTranslations[language] || params.row.itemName}
          <FormHelperText sx={{ marginTop: 0, fontSize: '10px' }}>
            {t('stock.itemNumberValue', { itemNumber: params.row.itemNumber })}
          </FormHelperText>
        </span>
      )
    },
    {
      field: 'availableQuantity',
      flex: 2,
      headerAlign: 'center',
      headerName: t('stock.availableAmount'),
      align: 'center',
      renderCell: (params) => (
        <Grid container alignItems="center">
          <Grid item xs={4} textAlign="right">
            {formatNumber(roundToTwoDecimals(params.value))}
          </Grid>
          <Grid item xs={8}>
            <FormControl sx={{ marginLeft: '4px' }} size="small">
              <Select
                value={params.row.unit}
                sx={{ width: '110px', height: '28px', fontSize: '14px' }}
                onChange={(event) => onChangeUnit(params.row.id, event.target.value as UnitType)}
              >
                {Object.values(UnitType).map((unit) => (
                  <MenuItem key={unit} value={unit}>
                    {t(`unitType.${unit}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )
    },
    {
      field: 'availablePacks',
      flex: 1,
      headerName: t('stock.availablePacks'),
      renderCell: (params) => (
        <span className="margin-top-4">
          {roundToInteger(params.row.availablePacks)}
          <FormHelperText sx={{ marginTop: 0, fontSize: '10px' }}>
            {t('stock.availablePacksHelperText', {
              quantity: roundToTwoDecimals(params.row.packQuantity),
              unit: t(`unitType.${params.row.unit}`)
            })}
          </FormHelperText>
        </span>
      )
    }
  ];

  const actionsColumns: GridColDef[] = [
    {
      field: 'orderPacks',
      flex: 1,
      headerName: t('stock.orderPacks'),
      renderCell: (params) => (
        <TextField
          className="table-input"
          type="number"
          InputProps={{
            inputProps: { min: 0 }
          }}
          error={!!(errors[params.row.id] as any)?.orderPacks}
          helperText={(errors[params.row.id] as any)?.orderPacks?.message || ''}
          {...register(`${params.row.id}.orderPacks`, {
            pattern: {
              value: /^([1-9]\d*)$/,
              message: t('stock.packsError')
            },
            onChange: () => setValue(`${params.row.id}.orderAllAvailable`, false)
          })}
        />
      )
    },
    {
      field: 'orderAllAvailable',
      flex: 1,
      headerName: t('stock.orderAllAvailable'),
      renderCell: (params) => (
        <Checkbox
          {...(register(`${params.row.id}.orderAllAvailable`),
          {
            onChange: (event, checked) => {
              setValue(`${params.row.id}.orderAllAvailable`, checked);
              if (checked) {
                setValue(`${params.row.id}.orderPacks`, roundToInteger(params.row.availablePacks));
              }
            }
          })}
          checked={watchAllFields[params.row.id]?.orderAllAvailable || false}
        />
      )
    },
    {
      field: 'orderQuantity',
      flex: 1,
      headerName: t('stock.orderQuantity'),
      valueGetter: getOrderQuantity,
      renderCell: (params) =>
        params.value
          ? t('order.items.quantityValue', {
              quantity: formatNumber(roundToTwoDecimals(params.value)),
              unit: t(`unitType.${params.row.unit}`)
            })
          : ''
    },
    {
      field: 'add',
      width: 50,
      headerName: '',
      sortable: false,
      align: 'right',
      renderCell: (params: GridRenderCellParams<any, void>) => (
        <IconButton onClick={() => openOrderSelection(params)}>
          <Icon>shopping_cart</Icon>
        </IconButton>
      )
    }
  ];

  return (
    <>
      <Grid container>
        <FilterArea labelKey="stock.searchItemsTitle" onClear={() => dispatch(clearFilter())}>
          <Filter
            id="searchField"
            label={t('stock.searchField')}
            onChange={(event) => {
              handleSearchFieldInputChange('searchField', event.target.value);
              debouncedSearchChangeHandler('searchField', event.target.value);
            }}
            text
            value={searchFieldValue}
            width="290px"
          />

          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  id="onlyAvailableItems"
                  checked={filter.onlyAvailableItems || false}
                  onChange={handleOnlyAvailableItemsChange.bind(this, 'onlyAvailableItems')}
                />
              }
              label={t('stock.onlyAvailableItems')}
            />
          </Grid>

          <Filter
            id="customerItemType"
            extra
            label={t('stock.customerItemType')}
            loading={isCustomerItemTypeOptionsLoading}
            minWidth="220px"
            multiple
            onChange={handleSelectChange.bind(this, 'customerItemType')}
            options={customerItemTypeOptions}
            value={filter.customerItemType || []}
          />

          <ItemAttributesFilter
            disabled={false}
            extra
            filter={filter}
            handleSelectChange={handleSelectChange}
          />
        </FilterArea>
        <Grid container className="margin-top-16 width-100">
          <Grid item xs sx={{ height: '65vh' }}>
            <DataGridPro
              loading={isLoading || isFetching}
              columns={[...columns, ...(canEdit ? actionsColumns : [])]}
              disableColumnMenu
              disableRowSelectionOnClick
              disableVirtualization={process.env.NODE_ENV === 'test'}
              filterMode="server"
              columnHeaderHeight={46}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    customerItemNumber: !!user?.customNumbers,
                    customerItemName: !!user?.customNames
                  }
                }
              }}
              paginationModel={{ page, pageSize }}
              onPaginationModelChange={({ pageSize, page }) => {
                dispatch(setPageSize(pageSize));
                dispatch(setPage(page));
              }}
              onSortModelChange={(sort) => dispatch(setSort(sort))}
              pagination
              paginationMode="server"
              rowHeight={55}
              rows={(salesOrderItems?.content || []) as GridRowsProp}
              rowCount={salesOrderItems?.totalElements || 0}
              pageSizeOptions={[10, 25, 50, 100]}
              sortingMode="server"
              sortModel={sort}
            />
          </Grid>
        </Grid>
      </Grid>

      {canEdit && (
        <OrderSelection
          itemRequest={itemRequest}
          isOpen={orderSelectionOpen}
          handleClose={() => closeOrderSelection()}
        />
      )}
    </>
  );
};
